import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SpinnerService } from './shared/services/spinner.service';
import { AlertService } from './_alert/alert.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'emsp-admin-portal';
  options = {
    autoClose: true,
    keepAfterRouteChange: false
};
  constructor(public router: Router, public spinnerService: SpinnerService, public alertService: AlertService){
  }

  ngOnInit() {
  }
}

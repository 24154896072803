import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { SharedService } from '@app/shared/services/shared.service';
import { TokenService } from '@app/shared/services/token.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from '@app/shared/services/spinner.service';
import { AlertService } from '@app/_alert/alert.service';
import { StoreService } from '@app/shared/services/store.service';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
  };
  isCustomAuth = false;

  constructor(
    private authenticationService: SharedService,
    private router: Router,
    private tokenService: TokenService,
    private spinnerService: SpinnerService,
    private alertService: AlertService,
    private storeService: StoreService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.spinnerService.show();
    const token = this.tokenService.getToken();
    const refreshToken = this.tokenService.getRefreshToken();

    // console.log('request-params:::::', request);
    // console.log('request-body:::::', request);
    // console.log('request-url:::::', request.url);

    if (request.url.includes('isCustomAuth=true')) {
      this.isCustomAuth = true;
    }

    // console.log('final-isCustomAuth:::::', this.isCustomAuth);

    if (!this.isCustomAuth) {
      // don't add token in header if it already added
      if (token) {
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });
      } else if (request.headers.has('useStoreToken')) {
        let authToken = this.storeService.get('accessToken');
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        });
      }
    }

    if (
      !request.headers.has('Ingore-ContentType') &&
      !request.headers.has('Content-Type')
    ) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    if (
      request.headers.has('Text-Content-Type') &&
      request.headers.has('Text-Content-Type')
    ) {
      request = request.clone({
        headers: request.headers.set('Accept', 'text/csv'),
      });
    }

    if (
      request.headers.has('PDF-Content-Type') &&
      request.headers.has('PDF-Content-Type')
    ) {
      request = request.clone({
        headers: request.headers.set('Accept', 'application/pdf'),
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.spinnerService.hide();
          // this.alertService.success("request success",this.options);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        console.log('request:::::', request);
        console.log('error:::::', error);

        this.alertService.error(
          'request failed Please try again',
          this.options
        );
        this.spinnerService.hide();
        if (error.status === 401) {
          if (error.error.error === 'invalid_token') {
            this.authenticationService
              .refreshToken({ refresh_token: refreshToken })
              .subscribe(() => {
                location.reload();
              });
          } else if (this.isCustomAuth) {
            // redirect on unauthorized page
            this.router
              .navigate(['unauthorized'])
              .then((_) => console.log('redirect to unauthorized'));
          } else {
            // redirect on login if 401 response returned from api
            this.router
              .navigate(['login'])
              .then((_) => console.log('redirect to login'));
          }
        }
        return throwError(error);
      })
    );
  }
}

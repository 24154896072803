<div class="main-div bg">
  <div>
    <div class="login-logo">
      <img src="assets/bg-images/evnnovator-logo-4.webp" style="width: 25rem" />
    </div>
    <mat-card class="my-card">
      <form class="my-form" (ngSubmit)="login()">
        <mat-card-content>
          <h4>Login</h4>
          <mat-form-field appearance="outline">
            <mat-label>Email/Username <span style="color: red;font-weight: bolder;">*</span></mat-label>
            <input
              matInput
              [(ngModel)]="email"
              [ngModelOptions]="{ standalone: true }"
              autofocus
            />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password <span style="color: red;font-weight: bolder;">*</span></mat-label>
            <input
              matInput
              type="password"
              [(ngModel)]="password"
              [ngModelOptions]="{ standalone: true }"
            />
          </mat-form-field>
          <!-- <mat-checkbox >Check me!</mat-checkbox> -->
          <div style="color: red">{{ errorMsg }}</div>
          <p *ngIf="incorrectCredentials"  style="color: red">
            You have entered an invalid email or password.
          </p>
          <p *ngIf="noWhitespaceValidator(email) || noWhitespaceValidator(password)"  style="color: red">
            Please enter valid details.
          </p>
        </mat-card-content>
        <mat-card-actions>
          <button [disabled]="noWhitespaceValidator(email) || noWhitespaceValidator(password)"  mat-raised-button type="submit" color="primary">LOGIN</button>
          <p class="forgot-text">
            Forgot password?
            <a routerLink="/forgot-password" class="a-link">Reset</a>
          </p>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
</div>

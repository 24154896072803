import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RoleService } from '@app/shared/services/rbac/role.service';
import { filter, map } from 'rxjs/operators';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
})
export class DashboardLayoutComponent implements OnInit {
  events: string[] = [];
  opened: boolean = true;
  isExpanded: boolean = false;
  appTitle = '';
  user: any;
  appCustomer = '';
  matDrawerWidth = '340px';
  windowInnerHeight = `${window.innerHeight - 91}px`;

  constructor(
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    public sharedservice: SharedService,
    public RoleService: RoleService
  ) {
    sharedservice = this.sharedservice;
    this.loadAppTitle();
  }

  ngOnInit() {
    this.appCustomer = this.sharedservice.getFleetId();
  }

  loadAppTitle() {
    this.appTitle = this.titleService.getTitle();
    this.user = JSON.parse(localStorage.getItem('currentuser'));
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
          return this.appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.appTitle = ttl;
      });
  }
  updateExpand(): void {
    // this.sharedservice.setIsExpand(!this.sharedservice.isExpanded);
    this.isExpanded = !this.isExpanded;
  }
  logout() {
    this.sharedservice.logout();
    this.router.navigate(['/login']);
  }

  hasReadAccess(module) {
    return this.RoleService.hasReadAccess(module);
  }
}

import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { SharedService } from '@app/shared/services/shared.service';
import { StoreService } from '@app/shared/services/store.service';
import { TokenService } from '@app/shared/services/token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: SharedService,
    private tokenService: TokenService,
    private storeService: StoreService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url: string = state.url;
    const userRole = this.authenticationService.getUserRole();

    if (
      route.data &&
      route.data.roles &&
      route.data.roles.indexOf(userRole) == -1
    ) {
      // this.router.navigate(['/unauthorized']);
      this.router.navigate(['/login']);
      return false;
    }

    return this.checkLogin(url, state);
  }

  checkLogin(url: string, state: any) {
    if (this.tokenService.getRefreshToken()) {
      return true;
    }

    if (this.storeService.getToken()) {
      return true;
    }

    this.authenticationService.redirectUrl = url;
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });

    return false;
  }
}

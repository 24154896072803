import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  open(message = '') {
    this.snackBar.open(message, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 2000,
      politeness: 'polite'
    });
  }

  openToster(message = '',className: string) {
    this.snackBar.open(message, 'X', {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 2000,
      politeness: 'polite',
      panelClass: className
    });
  }

}
